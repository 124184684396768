import appSettings from "../../appSettings";
import EventBus from 'common/event-bus'

export const AuthServiceEvents = Object.freeze({
    HeaderChanged: 'auth-header-changed',
    AuthenticatedUserChanged: 'authenticated-user-changed',
    SessionCleared: 'session-cleared',
});

export const authServiceEventBus = new EventBus(AuthServiceEvents);

const authHeaderKey = `cb${appSettings.appType}authHdr`;
const authUserKey = `cb${appSettings.appType}authUsr`;

const getDefaultState = () => {
    return {
        memoryStore: {},
        firstTime: true
    };
};

// state
const state = getDefaultState();

// getters
export const getters = {
    getSessionValue: (state) => (key) => sessionStorage.getItem(key),
    authHeader: (state, getters) => getters.getSessionValue(authHeaderKey),
    getAuthenticatedUser: (state, getters) => {
        const user = getters.getSessionValue(authUserKey);
        return user ? JSON.parse(user) : null;
    },
    getAuthenticatedUserId: (state, getters) => {
        const user = getters.getAuthenticatedUser;
        return (user) ? user.id : null;
    }
};

// actions
export const actions = {
    async setAuthHeader({state, commit, getters, dispatch}, value) {
        await dispatch('setSessionValue', {key: authHeaderKey, value});
        authServiceEventBus.emit(AuthServiceEvents.HeaderChanged);

        // When the app is first bootstrapped, the header is set
        // but the user may just be hanging out in session storage,
        // so we want to "emit" that it changed the first time.
        if (state.firstTime) {
            const user = getters['getAuthenticatedUser'];
            await dispatch('setAuthenticatedUser', user);
            commit('setFirstTime', false)
        }
    },
    async setAuthenticatedUser({dispatch}, val) {
        await dispatch('setSessionValue', {key: authUserKey, value: val ? JSON.stringify(val) : null});
        authServiceEventBus.emit(AuthServiceEvents.AuthenticatedUserChanged, {user: val});
    },
    async clearAuthenticatedUser({dispatch}) {
        await dispatch('setAuthenticatedUser', null);
    },
    async setAuthenticatedUserId ({dispatch}, authUserId) {
        await dispatch('setAuthenticatedUser', {id: authUserId});
    },
    async logout({dispatch}) {
        await dispatch('clearSession');
        await dispatch('setAuthenticatedUser', null); // explicitly clear the authenticated user (so an event fires)
    },
    clearSession({commit}) {
        commit('clearMemory');
        var i = sessionStorage.length;
        while (i--) {
            var key = sessionStorage.key(i);
            if (key.indexOf(appSettings.appType) !== -1)
                sessionStorage.removeItem(key);
        }
        authServiceEventBus.emit(AuthServiceEvents.SessionCleared);
    },
    setSessionValue({commit}, {key, value}) {
        if (value !== null && typeof value !== 'undefined') {
            sessionStorage.setItem(key, value);
        } else {
            commit('deleteMemoryItem', key);
            sessionStorage.removeItem(key);
        }
    },
    reset({commit}) {
        commit('resetState');
    },
};

// mutations
export const mutations = {
    setFirstTime(state, value) {
        state.firstTime = value
    },
    clearMemory(state) {
        state.memoryStore = {}
    },
    setMemoryItem(state, key, value) {
        state.memoryStore[key] = value
    },
    deleteMemoryItem(state, key) {
        delete state.memoryStore[key]
    },
    resetState(state) {
        Object.assign(state, getDefaultState());
    },
};

export default {
    state,
    getters,
    mutations,
    actions
};
