import appSettings from "appSettings";

export default {
  async getConnectionUrl(clientId, connectionId) {
    if (!clientId) {
      throw new Error(
        "The clientId parameter is required to get the connection."
      );
    }
    else if (!connectionId) {
      throw new Error(
        "The connectionId parameter is required to get the connection."
      )
    }
    return `${appSettings.apiBaseUrl}Clients/${encodeURIComponent(clientId)}/Connections/${encodeURIComponent(connectionId)}`;
  },
  async getConnectionSettingsUrl(clientId, connectionId) {
    if (!clientId) {
      throw new Error(
        "The clientId parameter is required to get the connection settings."
      );
    }
    else if (!connectionId) {
      throw new Error(
        "The connectionId parameter is required to get the connection settings."
      )
    }
    return `${appSettings.apiBaseUrl}Clients/${encodeURIComponent(clientId)}/Connections/${encodeURIComponent(connectionId)}/Settings`;
  },
  async getConnectionsListUrl(clientId, type) {
    if (!clientId) {
      throw new Error(
        "The clientId parameter is required to get the connection list."
      );
    }
    else if (!type) {
      throw new Error(
        "The type parameter is required to get the connection list."
      )
    }
    return `${appSettings.apiBaseUrl}Clients/${encodeURIComponent(clientId)}/Connections?connectionType=${encodeURIComponent(type)}`;
  },
  async getConnectionsActivityLogUrl(clientId, connectionId) {
    if (!clientId) {
      throw new Error(
        "The clientId parameter is required to get the connection activity log."
      );
    }
    else if (!connectionId) {
      throw new Error(
        "The connectionId parameter is required to get the connection activity log."
      )
    }
    return `${appSettings.apiBaseUrl}Clients/${encodeURIComponent(clientId)}/Connections/${encodeURIComponent(connectionId)}/ActivityLog`;
  },
  async getDataFieldsUrl(clientId) {
    if (!clientId) {
      throw new Error(
        "The clientId parameter is required to get datafields."
      );
    }
    
    return `${appSettings.apiBaseUrl}Clients/${encodeURIComponent(clientId)}/Sections/Datafields`;
  },
  async getDataFieldsPutGeneralUrl(clientId, schemaFieldId) {
    if (!clientId) {
      throw new Error(
        "The clientId parameter is required to put datafields general settings."
      );
    }

    if (!schemaFieldId) {
      throw new Error(
        "The schemaFieldId parameter is required to put datafields general settings."
      );
    }
    
    return `${appSettings.apiBaseUrl}Clients/${encodeURIComponent(clientId)}/Sections/Datafields/${encodeURIComponent(schemaFieldId)}/Sections/General`;
  }
};