import isPlainObject from 'lodash.isplainobject';
import "common/polyfills/string.endsWith.polyfill";

export default function LocationService ($window, $location, appSettings, Base64) {
  let previousRoute = null;
  return {
    buildRouteParts: function(route, data)
    {
      let finalRoute,
          dataUrl;
          
      if (arguments.length >= 3) {
        // when there are three parameters, we expect the second to be an object to merge parameters into the 
        // route string and the third to be a url to request data after changing routes
        finalRoute = route.split('/')
          .map((segment) => {
            // split the route by '/' and if the segment is a parameter (starts with :), 
            // look for a corresponding key/value in the data object
            // i.e. route: 'some/route/:id and data: { id: 1 } should be replaced with '/some/route/1'
            const isParameter = segment.charAt(0) === ':';
            const isOptional = segment.endsWith("?");
            return isParameter ? 
              data[segment.substring(1, isOptional ? segment.length - 1 : segment.length)] :
              segment;
          }).join('/');
        dataUrl = arguments[2];
      } else {
        // when there are two parameters we expect the "data" to be a url to request data after changing routes
        // sometimes a non-string value is being passed in here by mistake, so we need to ignore that and not
        // include a dataUrl
        finalRoute = route;
        dataUrl = data && typeof data === 'string' ? data : null;
      }

      return {
        route: finalRoute,
        dataUrl: dataUrl
      };
    },
    goToView: function(routeParts) {
      $location.url(routeParts.route);

      if (routeParts.params && isPlainObject(routeParts.params)) {
        Object.keys(routeParts.params).forEach((param) => {
          $location.search(param, routeParts.params[param]);
        });
      }

      if (routeParts.dataUrl) {
        $location.search('u', this.base64Encode(routeParts.dataUrl));
      }

      $location.replace();
    },
    changeView: function (route, data) {
      const routeParts = this.buildRouteParts.apply(this, arguments);			
      this.goToView(routeParts);
    },
    reloadView: function (route, query) {
      $location.search('u', this.base64Encode(query));
      $window.location.reload();
    },
    storeCurrentRoute: function (route) {
      this.previousRoute = route;
    },
    previousView: function () {
      this.changeView(this.previousRoute.originalPath, this.base64Decode(this.previousRoute.params.u));
    },
    fetchQuery: function () {
      return $location.search().u ? this.base64Decode($location.search().u) : "";
    },
    base64Encode: function (query) {
      return encodeURIComponent(Base64.encode(query));
    },
    base64Decode: function (query) {
      return Base64.decode(decodeURIComponent(query));
    }
  }
};

LocationService.$inject = ['$window', '$location', 'appSettings', 'Base64'];