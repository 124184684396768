import { i18n } from 'common/lang-config';

function translationKey(datafieldType) {
  return `datafields.types.${(datafieldType) || ''}`;
}

export default function(datafieldType) {
  // Types are somewhat dynamic from the backend, so if we don't have a mapping
  // in language file just return the value itself (which won't get translated)
  const i18nKey = translationKey(datafieldType);
  if (i18n.te(i18nKey))
    return i18n.t(i18nKey);
  else
    return datafieldType || '';
};