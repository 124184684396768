<template>
  <section class="forms-rose-acknowledgement">
    <cb-pdf-viewer v-if="showPdfPreview"
      class="forms-rose-acknowledgement-pdf-viewer"
      :url="pdfContentUrl" 
      :labels="pdfViewerLabels" />
    <div v-else v-html="roseTemplateHtml" class="rose-container"></div>

    <!--
      The roseTemplateHtml must be loaded before the rose/dojo lifecycle.
      Otherwise, we have observed errors (in IE11) that cause the form to not
      be able to render.  We apply the v-if here, instead of at the root section.      
    -->
    <div v-if="loaded" class="button-bar">
      <button class="cb-btn primary back" :disabled="disableBack" @click="loadRoseAsync()">
        <svg>
          <use v-bind="{'xlink:href': icons.leftArrow }" ></use>
        </svg>
        {{$t('common.back')}}
      </button>
      <button class="cb-btn primary next" @click="save()">
        {{saveText}}
        <svg>
          <use v-bind="{'xlink:href': icons.rightArrow }" ></use>
        </svg>
      </button>
    </div>
  </section>
</template>

<script>
  import LeftArrow from '@clickboarding/assets/icons/left-arrow.svg';
  import RightArrow from '@clickboarding/assets/icons/right-arrow.svg';
  import roseTemplate from 'rose/rose.html';
  import AppSettings from 'appSettings';
  import RoseFormService from 'common/services/roseForm.service';
  import CbPdfViewerDataProvider from "common/components/pdf/cb-pdf-viewer-data-provider";

  export default {
    name: 'forms-rose-acknowledgement',
    components: {
      CbPdfViewer: () => import('@clickboarding/pdf-viewer')
    },
    props: {
      roseUrl: {
        type: String,
        required: true
      },
      onComplete: {
        type: Function,
        required: false
      },
      overridePageData: {
        type: Object,
        required: false
      }
    },
    watch: {
      overridePageData: {
        immediate: true,
        handler(newPageData, oldPageData) {
          if (newPageData && (newPageData !== oldPageData)) {
            RoseFormService.createEvent(newPageData).then((eventData) => {
              RoseFormService.dispatchEvent("rose:formStart", eventData);
            });
          }
        }
      }
    },
    beforeCreate() {
      this.icons = {
        leftArrow: "#" + LeftArrow.id,
        rightArrow: "#" + RightArrow.id
      };
    },
    async created() {
      await this.loadRoseAsync();

      CbPdfViewerDataProvider.getData().then(data => {
        this.pdfViewerLabels = data;
      });
    },
    mounted () {
      var roseContainer = this.$el.querySelector('.rose-container');

      this.observer = new window.MutationObserver(function(mutations) {
        RoseFormService.applyCustomDomManipulation(roseContainer);
        this.observer.takeRecords(); //clears queue, we have no need to let them build up
      }.bind(this));
      
      if (roseContainer) {
        this.observer.observe(roseContainer, {
          subtree: true,
          childList: true,
          attributes: false,
          characterData: false
        });
      }
    },
    destroyed() {
      this.observer.disconnect();
    },
    computed: {
      showPdfPreview () {
        return this.pdfContentUrl && this.pdfViewerLabels;
      },
      disableBack () {
        return (this.showPdfPreview) ? false : true
      },
      saveText () {
        return (this.showPdfPreview) ? this.$t('common.submit') : this.$t('common.next');
      }
    },
    methods: {
      async roseCallbackAsync() {
        RoseFormService.rosePDF = null;

        let data = await this.$http.get(this.roseUrl);
        this.preview = data.body.preview;
        this.submit = data.body.save;
        this.loaded = true;

        const roseEvent = await RoseFormService.createEvent(data.data);
        
        if (this.preview){
          // Add event listeners and handlers
          RoseFormService.setEventUrl(this.preview.url);
        }
        RoseFormService.setPreviewOnly(!this.submit);
        RoseFormService.setDataCallback(this.onNextSuccess);

        // Emits on "Next/Submit"
        window.addEventListener("rose:formData", RoseFormService.dataHandler, false);
        // Emits on render complete
        window.addEventListener('rose:formRendered', RoseFormService.renderHandler, false);

        RoseFormService.dispatchEvent("rose:formStart", roseEvent);
      },
      async loadRoseAsync () {
        this.pdfContentUrl = null;
        this.submit = null;
        this.loaded = false;
        RoseFormService.dojoInit(this.roseCallbackAsync, this.roseUrl, AppSettings.baseUrl);
      },
      onNextSuccess (response) {
        if (this.showPdfPreview && this.onComplete) {
          // submit was successful on pdf preview page, close form
          this.onComplete();
        } else {
          // next was successful on data-entry page, advance to
          // preview and set next event handler to the submit url
          // NOTE: The submit.url requires the rose post body --
          // hence the need to use the RoseFormService to post
          this.pdfContentUrl = response.data.contentUrl;
          RoseFormService.setEventUrl(this.submit.url);
        }
      },
      save () {
        RoseFormService.dispatchEvent("rose:formGatherData", null);
      },
      shouldConfirmExit () {
        return true;
      }
    },
    data () {
      return {
        roseTemplateHtml: roseTemplate,
        eventType: null,
        preview: null,
        submit: null,
        pdfContentUrl: null,
        pdfViewerLabels: null,
        observer: null,
        loaded: false
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import 'common/css/legacy-forms-mixins';

  .forms-rose-acknowledgement /deep/ {
    @import 'common/css/roseform'; 
  }
  .rose-container {
    @include formContainer;
  }
  .forms-rose-acknowledgement-pdf-viewer {
    height: 45rem !important; // !important overrides the 100% height in cb-pdf-viewer
  }

  .button-bar {
    display: flex;
    margin: 1.5rem 0 0;

    .cb-btn {
      flex: 1;
      margin: 0;

      svg {
        width: 12px;
        height: 12px;
      }
    }
  }
</style>
