"use strict";
import typeNameAdapter from "datafields/typeNameAdapter";
import "common/polyfills/array.includes.polyfill";

function buildTypeDefinition (type, canCreate) {
  return {
    value: type,
    normalizedValue: type.toLowerCase(),
    label: typeNameAdapter(type),
    canCreate
  };
}

const types = Object.freeze({
    bool: buildTypeDefinition("Bool", false),
    integer: buildTypeDefinition("Integer", false),
    string: buildTypeDefinition("String", true),
    date: buildTypeDefinition("Date", true),
    datetime: buildTypeDefinition("DateTime", false),
    object: buildTypeDefinition("Object", true),
    array: buildTypeDefinition("Array", true),
    number: buildTypeDefinition("Number", false),
    phone: buildTypeDefinition("Phone", true),
    custom: buildTypeDefinition("Custom", false),
  });


function getDatafieldType(type) {
  const key = type.toLowerCase();
  return types[key] || null;
}

export { getDatafieldType };

export default types;
