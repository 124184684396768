// this file is intended to create an instance of axios
// the need behind this is we apply interceptors to axios when it interfaces with our local api
// however we do not want to apply those interceptors to other calls at large
// this service is currently used on auth.module.js where the local interceptors are set up

import axios from 'axios';
const axiosLocal = axios.create();

export {
  axiosLocal as AxiosLocal
}