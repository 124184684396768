export default function ModalDirective ($compile, appSettings) {
	var linker = function (scope, element, attrs) {
		scope.appSettings = appSettings;
		if (scope.data.hasCloseX === undefined )
			scope.data.hasCloseX = true ;
		var content = angular.element(document.querySelector('#dContent'));
		content.html(scope.data.message);
		$compile(content.contents())(scope);

		scope.printClick = function (e) {
			window.print();
		};
	}

	return {
		restrict: "E",
		replace: true,
		link: linker,
		template: require('common/modal.html'),
		scope: {
			data: "=",
			closeClick: '&',
			okClick: '&'
		}
	};
};

ModalDirective.$inject = ['$compile', 'appSettings'];
