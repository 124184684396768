import EventBus from 'common/event-bus';

export const AdminAppEvents = Object.freeze({
  ClientChanged: 'client-changed',
  UserSignedOut: 'user-signed-out',
  UserSignedIn: 'user-signed-in'
});

// NOTE: For consistency with other event bus exports (which are usually
// named exports with a service), we export this as a named export rather
// than a default).
export const adminAppEventBus = new EventBus(AdminAppEvents);
