"use strict"
import HeaderService from 'header/header.service';

export let headerService;
export default angular.module('header-module', [])
	.service('HeaderService', HeaderService)
	.run(['$injector', function ($injector) {
		headerService = $injector.get('HeaderService');
	}])
	.name;
