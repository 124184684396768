
import Vue from "vue";
import VueI18n from "vue-i18n";

// Import Language Translation Files
import bg from "lang/bg.json";
import de from "lang/de.json";
import enGB from "lang/en-GB.json";
import enUS from "lang/en-US.json";
import esMX from "lang/es-MX.json";
import es from "lang/es.json";
import fil from "lang/fil.json";
import frCA from "lang/fr-CA.json";
import fr from "lang/fr.json";
import hi from "lang/hi.json";
import hu from "lang/hu.json";
import it from "lang/it.json";
import ja from "lang/ja.json";
import ko from "lang/ko.json";
import ms from "lang/ms.json";
import nl from "lang/nl.json";
import pl from "lang/pl.json";
import ptBR from "lang/pt-BR.json";
import pt from "lang/pt.json";
import th from "lang/th.json";
import zhHans from "lang/zh-HANS.json";
import common_bg from "common/lang/bg.json";
import common_de from "common/lang/de.json";
import common_enGB from "common/lang/en-GB.json";
import common_enUS from "common/lang/en-US.json";
import common_esMX from "common/lang/es-MX.json";
import common_es from "common/lang/es.json";
import common_fil from "common/lang/fil.json";
import common_frCA from "common/lang/fr-CA.json";
import common_fr from "common/lang/fr.json";
import common_hi from "common/lang/hi.json";
import common_hu from "common/lang/hu.json";
import common_it from "common/lang/it.json";
import common_ja from "common/lang/ja.json";
import common_ko from "common/lang/ko.json";
import common_ms from "common/lang/ms.json";
import common_nl from "common/lang/nl.json";
import common_pl from "common/lang/pl.json";
import common_ptBR from "common/lang/pt-BR.json";
import common_pt from "common/lang/pt.json";
import common_th from "common/lang/th.json";
import common_zhHans from "common/lang/zh-HANS.json";

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: "en-US",
  fallbackLocale: "en-US",
  messages: {
    "bg": common_bg,
    "de": common_de,
    "en-GB": common_enGB,
    "en-US": common_enUS,
    "es-MX": common_esMX,
    "es": common_es,
    "fil": common_fil,
    "fr-CA": common_frCA,
    "fr": common_fr,
    "hi": common_hi,
    "hu": common_hu,
    "it": common_it,
    "ja": common_ja,
    "ko": common_ko,
    "ms": common_ms,
    "nl": common_nl,
    "pl": common_pl,
    "pt-BR": common_ptBR,
    "pt": common_pt,
    "th": common_th,
    "zh-Hans": common_zhHans
  }
});
i18n.mergeLocaleMessage('bg', bg);
i18n.mergeLocaleMessage('de', de);
i18n.mergeLocaleMessage('en-GB', enGB);
i18n.mergeLocaleMessage('en-US', enUS);
i18n.mergeLocaleMessage('es-MX', esMX);
i18n.mergeLocaleMessage('es', es);
i18n.mergeLocaleMessage('fil', fil);
i18n.mergeLocaleMessage('fr-CA', frCA);
i18n.mergeLocaleMessage('fr', fr);
i18n.mergeLocaleMessage('hi', hi);
i18n.mergeLocaleMessage('hu', hu);
i18n.mergeLocaleMessage('it', it);
i18n.mergeLocaleMessage('ja', ja);
i18n.mergeLocaleMessage('ko', ko);
i18n.mergeLocaleMessage('ms', ms);
i18n.mergeLocaleMessage('nl', nl);
i18n.mergeLocaleMessage('pl', pl);
i18n.mergeLocaleMessage('pt-BR', ptBR);
i18n.mergeLocaleMessage('pt', pt);
i18n.mergeLocaleMessage('th', th);
i18n.mergeLocaleMessage('zh-Hans', zhHans);

export { i18n };