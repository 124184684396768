import { v1 as uuidv1 } from 'uuid';

const analyticsService = {
  identify(userId, traits) {
    analytics.identify(userId, { ...traits });
  },
  track(event, properties, parentEventId) {
    analytics.track(event, 
      { 
        metadata: { 
          event_id: uuidv1(),
          parent_id: parentEventId ? parentEventId : ''
        }, 
        ...properties 
      });
  },
  reset() {
    analytics.reset();
  }
}

export default analyticsService;