import 'ngVue';
import "ngVue/build/plugins";
import "common/polyfills/array.includes.polyfill"; //required for 'ngVue.plugins'
import { i18n } from 'common/lang-config';

export default (settings) => angular.module('ngVue-module', ['ngVue', 'ngVue.plugins'])
  .config([
    "$ngVueProvider",
    function($ngVueProvider) {
      $ngVueProvider.setRootVueInstanceProps({ i18n, ...(settings && settings.additionalRootVueInstanceProps) });
    }
  ])
  .name;
