const getDefaultState = () => {
  return {
    searchTerm: null,
  };
}

// state
const state = getDefaultState();

// getters
export const getters = {
  persistedSearchTerm: (state) => {
    return state.searchTerm;
  }
};

// actions
export const actions = {
  persistSearchTerm({ commit }, searchTerm) {
    commit('searchTerm', searchTerm);
  },
  reset({ commit }) {
    commit('resetState');
  }
};

// mutations
export const mutations = {
  searchTerm(state, searchTerm){
    state.searchTerm = searchTerm;
  },
  resetState (state) {
    Object.assign(state, getDefaultState())
  },
};

export default {
  state,
  getters,
  mutations,
  actions
};
