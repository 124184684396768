<template>
  <footer class="cb-footer-container d-flex align-items-center justify-content-center flex-column flex-sm-row">
    <span class="mx-1">&copy; {{currentYear}} Click Boarding, LLC.  All Rights Reserved.</span>
    <span v-if="termsUrl || privacyUrl" class="mx-1 cb-footer-links">
      <b-link v-if="termsUrl" @click="loadTermsContent">{{$t('common.termsAndConditions')}}</b-link>
      <span class="mx-2" aria-hidden="true">|</span>
      <b-link v-if="privacyUrl" @click="loadPrivacyContent">{{$t('common.privacyPolicy')}}</b-link>
      <template v-if="showDisclaimer">
        <span class="mx-2" aria-hidden="true">|</span>
        <b-link :href="`${zendeskHelpCenterUrl}/en-us/articles/360049484334-Disclaimer-For-Forms`" target="_blank">{{$t('footer.formsDisclaimer')}}</b-link>
      </template>
    </span>
    <b-modal id="cb-footer-terms-privacy-modal"
        size="xl"
        scrollable
        hide-footer
        no-close-on-backdrop
        :aria-label="modalTitle"
        :return-focus="returnFocusElement"
        @close="closeModal">
      <template v-slot:default="{ close }">
        <div class="legal-content">
          <div v-html="legalContent" />
          <b-button variant="primary" block @click="close">{{$t('common.done')}}</b-button>
        </div>
      </template>
    </b-modal>
  </footer>
</template>

<script>
import get from "lodash.get";
import { AxiosLocal as axios } from "common/services/axiosLocal.service";
import AppSettings from 'appSettings';

export default {
  name: "cb-footer",
  props: {
    termsUrl: {
      type: String,
      required: false
    },
    privacyUrl: {
      type: String,
      required: false
    },
    showDisclaimer: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  created () {
    this.currentYear = new window.Date().getFullYear();
  },
  methods: {
    async loadTermsContent (event) {
      this.legalContent = this.termsUrl ? (await axios.get(this.termsUrl)).data.content : null;
      this.returnFocusElement = event.target;
      this.modalTitle = this.$t('common.termsAndConditions');
      this.$bvModal.show('cb-footer-terms-privacy-modal');
    },
    async loadPrivacyContent (event) {
      this.legalContent = this.privacyUrl ? (await axios.get(this.privacyUrl)).data.content : null;
      this.returnFocusElement = event.target;
      this.modalTitle = this.$t('common.privacyPolicy');
      this.$bvModal.show('cb-footer-terms-privacy-modal');
    },
    closeModal () {
      this.legalContent = null;
    }
  },
  data () {
    return {
      legalContent: null,
      modalTitle: null,
      returnFocusElement: null,
      zendeskHelpCenterUrl: AppSettings.zendeskHelpCenterUrl
    };
  }
}
</script>
<style lang="scss" scoped>
  @import '@clickboarding/style/mixins';
  @import 'common/css/legal';

  .cb-footer-container {
    width: 100%;
    height: 4rem;
    position: absolute;
    bottom: 0; left: 0;

    @include font-size-content-root;
  }

  .legal-content {
    // legal content is dynamic from the API
    // so it won't have the scoped style
    // data attributes
    /deep/ {
      @include legal-content;
    }
  }
</style>