import activityService from 'api/activityService';

const shouldRequestClientUsers = (state, url) => {
  // Should only make a request if there are no users in state and there is not a pending request
  return state.requestUrl !== url || (!state.clientUsers.length && !state.requestPending);
}

const getDefaultState = () => {
  return {
    clientUsers: [],
    requestUrl: '',
    requestPending: false,
  }
}

// State
const state = getDefaultState();

// Getters
export const getters = {
  clientUsers: (state) => {
    return state.clientUsers;
  }
 }

// Actions
export const actions = {
  async getUserListAsync({ commit, state }, getUserListUrl ) {
    if (shouldRequestClientUsers(state, getUserListUrl)) {
      commit('setRequestStatus', true);
      commit('setRequestUrl', getUserListUrl);
      const data = await activityService.getUserListAsync(getUserListUrl);
      commit('setUserList', data);
      commit('setRequestStatus', false);
    }
  },
  resetActivities({ commit, state }) {
    commit('resetActivities', state);
  },
  reset({ commit, state }) {
    commit('resetActivities', state);
  },
}

// Mutations
export const mutations = {
  setUserList (state, clientUsers) {
    state.clientUsers = clientUsers || [];
  },
  resetActivities(state) {
    Object.assign(state, getDefaultState());
  },
  setRequestStatus(state, payload) {
    state.requestPending = payload;
  },
  setRequestUrl(state, payload) {
    state.requestUrl = payload;
  }
}

export default {
  state,
  getters,
  actions,
  mutations
};
