import Vue from 'vue';

export default class EventBus {
  
  constructor(allowedEvents) {
    this._bus = new Vue();

    this.allowedEvents = new Set(Object.values(allowedEvents || []));
  }

  on(eventKey, callback) {
    this._verifyEventKeyIsAllowed(eventKey);

    this._bus.$on(eventKey, callback);

    return {
      unsubscribe: () => {
        this._bus.$off(eventKey, callback);
      }
    }
  }

  emit(eventKey, arg) {
    this._verifyEventKeyIsAllowed(eventKey);

    this._bus.$emit(eventKey, arg);
  }

  _verifyEventKeyIsAllowed(eventKey) {
    if (!this.allowedEvents.has(eventKey)) {
      throw new Error(`The eventKey '${eventKey}' is not supported by this EventBus. Check that it is spelled correctly and has been added to the set of allowedEvents.`);
    }
  }
};
