import urlService from "api/urlService.js";
import { AxiosLocal as axios } from "common/services/axiosLocal.service";

export default {
  async get(clientId) {
    const apiUrl = await urlService.getDataFieldsUrl(clientId);
    const response = await axios.get(apiUrl);
    return response.data;
  },

  async create(clientId, model) {
    const apiUrl = await urlService.getDataFieldsUrl(clientId);
    await axios.post(apiUrl, model);
  },

  async putGeneral(clientId, schemaFieldId, model) {
    const apiUrl = await urlService.getDataFieldsPutGeneralUrl(clientId, schemaFieldId);
    await axios.put(apiUrl, model);
  }
};