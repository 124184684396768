import Vue from 'vue';
import appSettings from 'appSettings';

class CbPdfViewerDataProvider {
  constructor() {
    this.data = null;
    this.getData = this.getData.bind(this);
  }

  async getData () {
    if (this.data === null) {
      const response = await Vue.http.get(appSettings.apiBaseUrl + "SharedResources/PdfViewer");
      
      this.data = {
        zoomOutButtonTitle: response.body.zoomOutLabel,
        fitToWidthButtonTitle: response.body.fitToWidthLabel,
        zoomInButtonTitle: response.body.zoomInLabel,
        previousButtonTitle: response.body.previousLabel,
        nextButtonTitle: response.body.nextLabel,
        downloadButtonTitle: response.body.downloadLabel
      };
    }

    return this.data;
  }
}

export default new CbPdfViewerDataProvider();
