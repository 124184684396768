import DeviceDetector from 'ua-parser-js';

export default {
    handleUnsupported: function () {
        const deviceDetector = new DeviceDetector();
        let navigator = deviceDetector.getResult();
        if (navigator.browser.name === 'IE' && +navigator.browser.major <= 11) {
            window.location.replace(document.getElementById('linkRoot').href + 'unsupported.html');
        }
    }
};
