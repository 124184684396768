class IconProvider {
  static getPath (iconIdentifier) {
    /* Source derived from: https://gist.github.com/leonderijke/c5cf7c5b2e424c0061d2 */
    return window.location.href.replace(window.location.hash, "") + '#' + iconIdentifier;
  }
  static loadIconSprite () {
    const iconSprite = import('!raw-loader!common/assets/icons/icon-sprite.svg').then((content) => {
      const spriteContainerId = 'icon-sprite';
      if (document.getElementById(spriteContainerId)) return;

      const spriteContainerElement = document.createElement('div');
      spriteContainerElement.id = spriteContainerId;
      // webpack 4 includes the default property per the dynamic import spec, 
      // while webpack 2 bypasses it so you can access the default export with 
      // accessing a "default" property. 
      spriteContainerElement.innerHTML = content.default || content; 
      document.body.insertBefore(spriteContainerElement, document.body.firstChild);
    });	
  }
}

export { IconProvider };