import 'common/polyfills/smoothscroll.polyfill';
import { AxiosLocal } from 'common/services/axiosLocal.service';

	var eventData = {},
  eventUrl,
  pageRendered = false,
  previewOnly = false,
  dataCallback = null;

//handle custom implementation of required class
function applyRequiredClass (field, label) {
  var isRequiredFormField = field.querySelector('.rose-input.rose-required, textarea.rose-required') !== null;

  if(isRequiredFormField) {
    label.querySelector('.roseLabel').classList.add('required');
  }
}

//apply class to radio labels so we can override their styles
function applyClassToRadioLabels (field, label) {
  //roseRadioList-wrapper is the css mechanism we use to apply the center to the input, so we should be consistent here
  var isRadioFormField = field.querySelector('.roseRadioList-wrapper input[type="radio"]') !== null;

  if(isRadioFormField) {
    label.classList.add('roseRadioListLabel-wrapper');
  }
}

function removeColonFromLabels (label) {
  var newLabel = label.querySelector('.roseLabel').textContent.replace(/\:$/, '');
  label.querySelector('.roseLabel').textContent = newLabel;
}

function copyGridColumnHeadersToHiddenLabels(container) {
  var roseGridHeaderCells = container.querySelectorAll('.rose-gridWrapper thead th');

  for(var i = 0; i < roseGridHeaderCells.length; i++) {
    var currentHeaderCell = roseGridHeaderCells[i];

    var roseGridDataRows = container.querySelectorAll('.rose-gridWrapper tbody tr');

    for(var j = 0; j < roseGridDataRows.length; j++) {
      var currentRow = roseGridDataRows[j];
      var currentRowCells = currentRow.getElementsByTagName('td');

      /* i because we need to get the cell that in the same position as the current header */
      var currentCell = currentRowCells[i];
      /* we catch all elements every time, so this check ensures we don't apply this change twice to the same cells */
      if(!currentCell.getElementsByTagName('label').length) {
        var currentCellContent = currentCell.innerHTML;
        if(currentHeaderCell.innerHTML.trim().length > 0) {
          var responsiveLabel = '<label class="cb-rose-grid-responsive-label">' + currentHeaderCell.innerHTML + ': </label>';
          currentCell.innerHTML = responsiveLabel + currentCellContent;
        }
      }
    }
  }
}

var RoseFormService = {
  rosePDF: null,
  uiMessages: null,
  setUiMessages: function (message) {
    RoseFormService.uiMessages = message;
  },
  dojoInit: function(cb, url, baseUrl) {
    eventUrl = url;         

    window.require(["dojo/ready"], function (ready) {
      ready(function () {
        window.require(["rose/roseEventListener"], function (roseEventListener) {
          roseEventListener.init(baseUrl);

          cb();
        });
        // this is currently specifically for interfacing with iframes relating to PDFs generated by roseForms
        window.require(["ko/knockout", "rose/validatorsMap2"], function(ko, validatorsMap2){
          window.require(['rose/formModelMap2'], function(formModelMap2) {
            var subjectBinding = {
              simple: function (element, subjectVM) {
                return ko.computed({
                  read: function () {
                    if (!element.datafield || !subjectVM || !subjectVM()) {
                      return undefined;
                    }
                    if (!(element.datafield in subjectVM())) {
                      subjectVM()[element.datafield] = ko.observable(undefined).extend({ trackChange: true });
                    }
                    return subjectVM()[element.datafield]();
                  },
                  write: function (value) {
                    if (element.datafield && subjectVM) {
                      if (!(element.datafield in subjectVM())) {
                        subjectVM()[element.datafield] = ko.observable(undefined).extend({ trackChange: true });
                      }
                      subjectVM()[element.datafield](value);
                    }
                  }
                });
              }
            };

            formModelMap2._mapUri = function (element, previousSibling, subjectVM, getQuestionNumber) {
              element.questionNumber = undefined;
              element.height = 'auto';
              element.heightPx = 'auto';
              element.frameHeightPx = 'auto';

              if (element.URI) {
                element.URI = ko.observable(element.URI);
              } else {
                element.URI = subjectBinding.simple(element, subjectVM);
              }

              // Transform the uri, to use the custom PDF viewer
              var uri = element.URI();
              RoseFormService.rosePDF = window.location.origin + uri;
              element.viewerUri = null;

              validatorsMap2.mapEmptyValidtor(element);
            };
          });
        });

      });
    });
  },
  createEvent: function(data) {      
    return new Promise((resolve) => {
      eventData.roseSubjectModel = JSON.parse(data.subjectModelAsString);
      eventData.roseFormModel = JSON.parse(data.formModelAsString);
      eventData.roseNls = JSON.parse(data.localizedStringsAsString);
      eventData.roseDs = data.dataStoreGuid;

      resolve(eventData);
    });
  },
  getEventData: function() {      
    return new Promise((resolve) => {
      resolve(eventData);
    });
  },
  setEventUrl: function(newEventUrl) {
    eventUrl = newEventUrl;
  },
  setDataCallback: function(fn) {
    dataCallback = fn;
  },
  setPreviewOnly: function(newPreviewOnly) {
    previewOnly = newPreviewOnly;
  },
  dispatchEvent: function (name, data) {
    var evt = document.createEvent("CustomEvent");
    evt.data = data;
    evt.initCustomEvent(name, true, false, null);
    window.dispatchEvent(evt);
  },
  renderHandler: function(e) {
    pageRendered = true;

    // yuck, find a way to remove this
    if (/iPhone|iPod|iPad/.test(navigator.userAgent))
    {
      document.querySelectorAll('.rose-uri').forEach(function(elem) {
        elem.style['overflow'] = 'auto';
        elem.style['-webkit-overflow-scrolling'] = 'touch';
      });
    }
  },
  dataHandler: function (e) {
    if (e.data.isPageValid) {
      if (!previewOnly) {
       AxiosLocal.post(eventUrl, e.data).then((response) => {
         dataCallback(response);
       });
      }
    } else {
      if (RoseFormService.uiMessages) RoseFormService.uiMessages.showMessage('error','There are one or more problems with the data entered, you will need to fix the data marked with a red flag in order to continue.');

      var roseValidate = document.querySelector(".roseValidate");
      if (roseValidate && roseValidate.offsetParent) {	
        document.querySelector('html, body').scrollTo({ top: roseValidate.offsetParent.offsetTop - 90, behavior: 'smooth' });
      }      
    }
  },
  applyCustomDomManipulation: function(container) {
    var roseLabelContainers = container.querySelectorAll('.rose-input .formlabel');

    for (var i = 0; i < roseLabelContainers.length; i++) {
      var currentRoseLabel = roseLabelContainers[i];
      var nextSibling = currentRoseLabel.nextElementSibling;
      var isFormField = nextSibling.classList.contains('formfield');

      if (isFormField) {
        removeColonFromLabels(currentRoseLabel);

        applyRequiredClass(nextSibling, currentRoseLabel);

        applyClassToRadioLabels(nextSibling, currentRoseLabel);
      }
    }

    copyGridColumnHeadersToHiddenLabels(container);
  }
};

export default RoseFormService;