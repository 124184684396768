export default function addEventListenerForBodySelectPlaceholder() {
  Array.from(document.querySelectorAll('body select')).forEach(function(element) {
    element.addEventListener('change', function (event) {
      if(event.target.value === "") {
        event.target.classList.add('selectPlaceholder');
      }
      else {
        event.target.classList.remove('selectPlaceholder');
      }
    });
  });
}