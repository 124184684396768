import EventBus from 'common/event-bus';
import store from "../store";

export const HeaderServiceEvents = Object.freeze({
    HeaderChanged: 'header-changed'
});

export const headerServiceEventBus = new EventBus(HeaderServiceEvents);

export default function HeaderService($location, $http, appSettings, locationSvc, $rootScope) {
    var storage = "cb" + appSettings.appType + "hdrUrl";

    var svc = {};
    svc.headerUrl = undefined;
    svc.headerContent = {};

    async function requestHeaderData(url) {
        if (!url) {
            return console.error('no header url found. Either pass a url to the method, or ensure a url is set on the localSession');
        }

        svc.headerRequest = {
            httpRequest: $http.get(url),
            url: url
        };

        var response = await svc.headerRequest.httpRequest;
        let data = response.data;
        if (data.clientSelection !== undefined){
            store.commit('features/setFeatureFlags', data.clientSelection.client.clientFeatureFlags);
        }
        store.commit('features/setFeatureFlagToggle', appSettings.featureFlagFeatureToggle);
        store.commit('features/setUserName', data.firstName);

        angular.copy(data, svc.headerContent);

        //admin logout just redirects to the login url to log out.
        if (svc.headerContent.logout && svc.headerContent.logout.url) {
            appSettings.loginUrl = svc.headerContent.logout.url;
        }

        headerServiceEventBus.emit(HeaderServiceEvents.HeaderChanged, {
            header: svc.headerContent
        });
    }

    svc.getHeaderContent = async function () {
        if (!svc.headerUrl) svc.headerUrl = svc.getHeaderUrl();

        //if there is already a current request, wait for it instead of creating a new one.
        if(svc.headerRequest && svc.headerRequest.url === svc.headerUrl) {
            await svc.headerRequest.httpRequest;
        }
        else {
            await requestHeaderData(svc.headerUrl);
        }

        return svc.headerContent;
    }

    svc.logout = async function () {
        await store.dispatch('auth/logout');
        locationSvc.changeView(appSettings.viewLogin);
        $rootScope.$apply();
    }

    svc.reloadHeaderUrl = async function (newUrl) {
        const headerUrl = newUrl || svc.getHeaderUrl();
        svc.headerUrl = headerUrl;
        sessionStorage.setItem(storage, encodeURI(headerUrl));
        svc.headerRequest = null;

        return await svc.getHeaderContent();
    };

    svc.hasHeaderSession = function () {
        return !!sessionStorage.getItem(storage);
    }

    svc.getHeaderUrl = function () {
        const urlHParam = $location.search().h;
        const HeaderUrlParam = urlHParam ? locationSvc.base64Decode(urlHParam) : null;
        const HeaderUrlInSession = sessionStorage.getItem(storage);

        return HeaderUrlParam || HeaderUrlInSession || null;
    }

    svc.hasHeaderUrl = function () {
        const hasHeaderUrlParam = !!$location.search().h

        return hasHeaderUrlParam || svc.hasHeaderSession();
    }

    svc.hasHeaderData = function () {
        return svc.headerContent;
    };

    svc.shouldShowHeader = function () {
        return true;
    }

    return svc;
}

HeaderService.$inject = ['$location', '$http', 'appSettings', 'locationSvc', '$rootScope'];
