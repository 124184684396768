import 'css/theme.scss';

import 'common/css/icons.css';
import '../css/icons.css';
import '../css/siteelements.scss';
import '../css/site.scss';
import '../css/admin.css';
import '../css/components.css';
import '../css/doughnutPie.css';
import '../css/utility.css';
import '../css/views/candidate.css';
import '../css/views/dashboard.css';
import '../css/views/flows.css';
import '../css/views/general.css';
import '@clickboarding/vue-components/dist/vue-components.css';
import '@clickboarding/savage/dist/savage.css'