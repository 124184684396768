import datafieldsService from 'api/datafieldsService';
import { getDatafieldType } from 'datafields/datafield-types';
import clonedeep from 'lodash.clonedeep';
import 'common/polyfills/object.assign.polyfill';
import "common/polyfills/array.includes.polyfill";

const getDefaultState = () => {
  return {
    definition: null,
    userPermissions: {
      canCreate: false
    }
  };
}

// state
const state = getDefaultState();

// getters
export const getters = {
  findBySchemaFieldId: (state, getters) => (schemaFieldId) => {
    function _findBySchemaFieldId (datafield, schemaFieldId) {
      // if current datafield is a match, return it
      if (datafield.schemaFieldId === schemaFieldId)
        return datafield;

      const datafields = datafield.datafields || [];
      for (let i=0; i<datafields.length; i++) {
        const nestedMatch = _findBySchemaFieldId(datafields[i], schemaFieldId);

        // if nested datafields have match, return it
        if (nestedMatch) return nestedMatch;
      }

      return null;
    }

    const definition = getters['enrichedDefinition'];
    // short circuit if definition does not exist
    if (!definition) return null;
    return _findBySchemaFieldId(definition, schemaFieldId);
  },

  enrichedDefinition: (state) => {
    if (!state.definition) return null;

    const definition = clonedeep(state.definition);

    const enrichAllItemsAndChildren = (items, parent) => {
      items.forEach((item) => {
        enrichItemAndChildren(item, parent);
      });
    };

    const enrichItemAndChildren = (item, parent) => {
      item.disallowedNestedTypes = item.type === 'Array' ? ['Array'] : [];

      item.metadata = item.metadata || {};

      // title is more human friendly, but optional
      // historically; identifier may be less human
      // friendly but always present
      item.metadata.displayName = item.title || item.identifier;

      item.metadata.displayType = getDatafieldType(item.type).label;

      item.metadata.parent = parent;

      const itemFlags = item.flags || [];
      item.metadata.platformControlled = !itemFlags.includes('EditableTitle');
      item.metadata.editableTitle = itemFlags.includes('EditableTitle');

      let fullJsonPointer;

      if (!parent) {
        fullJsonPointer = item.jsonPointer;
      } else {
        fullJsonPointer = parent.type === 'Array'
          ? `${parent.metadata.fullJsonPointer}/-${item.jsonPointer}`
          : `${parent.metadata.fullJsonPointer}${item.jsonPointer}`;
      }

      item.metadata.fullJsonPointer = fullJsonPointer;

      // Using an empty Set and .add intentionally because the new
      // Set(iterable) constructor doesn't work in IE11
      item.metadata.identifiersInUseLower = new Set();

      if(item.datafields && item.datafields.length) {
        item.datafields.forEach(child => item.metadata.identifiersInUseLower.add(child.identifier.toLowerCase()));
      }

      if (item.datafields && item.datafields.length) {
        enrichAllItemsAndChildren(item.datafields, item);
      }
    };

    enrichItemAndChildren(definition, null);

    return definition;
  },

  userCanCreate: (state) => {
    return !!(state && state.userPermissions && state.userPermissions.canCreate);
  },

  userCanEditTitle: (state) => {
    return !!(state && state.userPermissions && state.userPermissions.canEditTitle);
  }
};

// actions
export const actions = {
  async load ({ commit, rootGetters }) {
    const data = await datafieldsService.get(rootGetters.clientId);
    commit('setDefinition', data.rootDatafield);
    commit('setUserPermissions', {
      userHasCreatePermission: data.userHasCreatePermission,
      userHasEditTitlePermission: data.userHasEditTitlePermission
    });
  },

  async loadIfNeeded ({ getters, dispatch }) {
    if(!(getters.enrichedDefinition)) await dispatch('load');
  },

  async createDatafield ({ dispatch, rootGetters }, model) {
    await datafieldsService.create(rootGetters.clientId, model);
    await dispatch('load');
  },

  async updateDatafieldGeneralSettings ({ dispatch, rootGetters }, [ schemaFieldId, model ]) {
    await datafieldsService.putGeneral(rootGetters.clientId, schemaFieldId, model);
    await dispatch('load');
  },

  reset({ commit }) {
    commit('resetState');
  }
};

// mutations
export const mutations = {
  setDefinition (state, definition) {
    state.definition = definition || {};
  },

  setUserPermissions (state, { userHasCreatePermission, userHasEditTitlePermission }) {
    state.userPermissions = {
      canCreate: !!(userHasCreatePermission),
      canEditTitle: !!(userHasEditTitlePermission)
    };
  },

  resetState (state) {
    Object.assign(state, getDefaultState())
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
