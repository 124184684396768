import Datafields from 'store/modules/datafields';
import Forms from 'store/modules/forms';
import Activities from 'store/modules/activities';
import Screenings from 'store/modules/screenings';
import Auth from 'store/modules/auth';
import Features from 'store/modules/features';

export default function () {
  return {
    'datafields': Datafields,
    'forms': Forms,
    'activities': Activities,
    'screenings': Screenings,
    'auth': Auth,
    'features': Features
  };
};