<!--
WARNING: This component was ported verbatim from angular to vue. Because of this
it uses old patterns and standards and should not be used as a reference.

Any changes to this component should result in movement toward our new
patterns and practices.
-->
<template>
  <section class="formI9 cb-candidate-form-formI9">
    <iframe v-if="trustedUrl" :src="trustedUrl" frameborder="0" class="w100 borderless" style="height:99%"></iframe>
  </section>
</template>

<script>
export default {
  name: "form-i9",
  props: {
    url: {
      type: String
    }
  },
  data() {
    return {
      formData: null,
      trustedUrl: null
    }
  },
  watch: {
    url: {
      immediate: true,
      handler: async function () {
        if (typeof this.url != 'undefined') {
          var response = await this.$http.get(this.url);
          this.formData = response.data;	
          this.trustedUrl = this.formData.contentUrl;
        }
      }
    }
  }
}
</script>

<style>

</style>