'use strict';
const getDefaultState = () => {
    return {
        provider: null,
        backUrl: null
    };
};

// state
const state = getDefaultState();

// getters
export const getters = {
    provider: (state) => state.provider,
    backUrl: (state) => state.backUrl
};

// actions
export const actions = {
    setProvider({ commit }, provider) {
        commit('setProvider', provider);
    },
    setBackUrl ({ commit }, link) {
        commit('setBackUrl', link);
    },
    reset({ commit }) {
        commit('resetState');
    }
};

// mutations
export const mutations = {
    setProvider(state, provider){
        state.provider = provider;
    },
    setBackUrl(state, link){
        state.backUrl = link;
    },
    resetState (state) {
        Object.assign(state, getDefaultState());
    },
};

export default {
    state,
    getters,
    mutations,
    actions
};
