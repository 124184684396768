class HttpStrategy {
  constructor() {
    this.preRequestHandler = null;
    this.handlePreRequest = this.handlePreRequest.bind(this);
    this.setPreRequestHandler = this.setPreRequestHandler.bind(this);
    this.requestErrorHandler = null;
    this.handleRequestError = this.handleRequestError.bind(this);
    this.setRequestErrorHandler = this.setRequestErrorHandler.bind(this);
    this.requestSuccessHandler = null;
    this.handleRequestSuccess = this.handleRequestSuccess.bind(this);
    this.setRequestSuccessHandler = this.setRequestSuccessHandler.bind(this);    
    this.requestCompleteHandler = null;
    this.handleRequestComplete = this.handleRequestComplete.bind(this);
    this.setRequestCompleteHandler = this.setRequestCompleteHandler.bind(this);    
  }

  handlePreRequest (request, loader) {
    return this.preRequestHandler(request, loader);
  }

  setPreRequestHandler (handler) {
    this.preRequestHandler = handler;
  }
  
  handleRequestError (response) {
    return this.requestErrorHandler(response);
  }

  setRequestErrorHandler (handler) {
    this.requestErrorHandler = handler;
  }

  handleRequestSuccess (response) {
    return this.requestSuccessHandler(response);
  }

  setRequestSuccessHandler (handler) {
    this.requestSuccessHandler = handler;
  }

  handleRequestComplete (response, loader) {
    return this.requestCompleteHandler(response, loader);
  }

  setRequestCompleteHandler (handler) {
    this.requestCompleteHandler = handler;
  }
}

const vueResourceStrategy = new HttpStrategy();  
const $httpStrategy = new HttpStrategy();
const uploadXhrStrategy = new HttpStrategy();
const axiosStrategy = new HttpStrategy();

export { 
  vueResourceStrategy as VueResourceStrategy,
  $httpStrategy as $httpStrategy,
  uploadXhrStrategy as UploadXhrStrategy,
  axiosStrategy as AxiosStrategy
}