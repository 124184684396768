/*
  This file install the BootstrapVue vue plugin.
*/

import Vue from "vue";
import { i18n } from 'common/lang-config';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import merge from "lodash.merge";

/*
  This is where we can configure any sitewide settings for BootstrapVue. 
  See https://bootstrap-vue.js.org/docs/misc/settings/#default-configuration
  for all available settings.
*/
const bootstrapVueGlobalSettings = {
  BModal: {
    headerCloseLabel: i18n.t('common.close')
  },
  BTable: {
    headVariant: 'light'
  },
  BFormDatepicker: {
    labelPrevYear: i18n.t("common.bDatepicker.previousYear"),
    labelPrevMonth: i18n.t("common.bDatepicker.previousMonth"),
    labelCurrentMonth: i18n.t("common.bDatepicker.currentMonth"),
    labelNextMonth: i18n.t("common.bDatepicker.nextMonth"),
    labelNextYear: i18n.t("common.bDatepicker.nextYear"),
    labelToday: i18n.t("common.bDatepicker.today"),
    labelSelected: i18n.t("common.bDatepicker.selectedDate"),
    labelNoDateSelected: i18n.t("common.bDatepicker.noDateSelected"),
    labelCalendar: i18n.t("common.bDatepicker.calendar"),
    labelNav: i18n.t("common.bDatepicker.calendarNavigation"),
    labelHelp: i18n.t("common.bDatepicker.helpText"),
    labelTodayButton: i18n.t("common.bDatepicker.selectToday"),
    labelResetButton: i18n.t("common.bDatepicker.reset"),
    labelCloseButton: i18n.t("common.bDatepicker.close")
  },
};

export default function (appSettings) {
  Vue.use(BootstrapVue, merge(
    {}, 
    bootstrapVueGlobalSettings, 
    appSettings || {}
  ));
  Vue.use(BootstrapVueIcons);
}
