import { AxiosLocal } from 'common/services/axiosLocal.service';
import Vue from 'common/vue.setup';
import AppRoutingModule from 'common/app-routing.module';
import AppSettingsModule from 'common/app-settings.module';
import PrimaryMessageBus from 'common/components/cb-primary-message-bus';
import { VueResourceStrategy, UploadXhrStrategy, AxiosStrategy } from 'common/http-strategy';
import store from "./store";
import appSettings from "./appSettings";

const authHeaderKey = `cb${appSettings.appType}authHdr`;

export let $ngVue;
export default angular.module('auth-module', [ AppRoutingModule, AppSettingsModule ])
  .config(["$httpProvider", function ($httpProvider) {
    var interceptor = ['$q', 'locationSvc', 'appSettings', function ($q, locationSvc, appSettings) {
      var checkAuthorization = async function (response) {
        if (response.status === 401 || response.status === 403) {
          response.noAlert = true;
          await store.dispatch('auth/logout');
          locationSvc.changeView(appSettings.viewLogin);
          return $q.reject(response);
        }
      }

      return {
        request: function (request) {
          var hdr = sessionStorage.getItem(authHeaderKey);
          if (hdr) request.headers["Authorization"] = hdr;
          return request;
        },
        response: async function (response) {
          checkAuthorization(response);
          var hdr = response.headers("Authorization");
          if (hdr) await store.dispatch('auth/setAuthHeader', hdr);
          return response;
        },
        responseError: function (rejection) {
          checkAuthorization(rejection);
          return $q.reject(rejection);
        }
      }
    }];

    $httpProvider.interceptors.push(interceptor);
  }])
  .run(['$timeout', 'cfpLoadingBar', '$rootScope', '$injector', 'locationSvc', 'appSettings', function($timeout, cfpLoadingBar, $rootScope, $injector, locationSvc, appSettings) {
    /* Setup PreRequest strategies */
    UploadXhrStrategy.setPreRequestHandler(function preRequestHandler (request, loader) {
      loader = $timeout(function () {
        cfpLoadingBar.start();
      }, 1250);

      request.setRequestHeader('Authorization', sessionStorage.getItem(authHeaderKey));
      
      return loader;
    });

    VueResourceStrategy.setPreRequestHandler(function preRequestHandler (request, loader) {
      loader = $timeout(function () {
        cfpLoadingBar.start();
      }, 1250);

      request.headers.set('Authorization', sessionStorage.getItem(authHeaderKey));

      return loader;
    });

    AxiosStrategy.setPreRequestHandler(function preRequestHandler (config) {
      config.cbLoader = $timeout(function () {
          cfpLoadingBar.start();
      }, 1250);

      config.headers.Authorization = sessionStorage.getItem(authHeaderKey);

      return config;
    });

    /* Setup RequestError strategies */
    UploadXhrStrategy.setRequestErrorHandler(async function(xhr) {
      const isSoftError = xhr.status > 399
        && xhr.status < 500
        && xhr.status !== 401 
        && xhr.status !== 403;

      if(isSoftError) {
        let response = JSON.parse(xhr.response);
        PrimaryMessageBus.$emit('send-error-message', response[0].message);
        return Promise.reject(xhr);
      } else if (xhr.status === 401 || xhr.status === 403) {
        await store.dispatch('auth/logout');
        locationSvc.changeView(appSettings.viewLogin);
        return Promise.reject(response);
      } else if (xhr.status >= 400) {
        $rootScope.$broadcast('cfpLoadingBar:error', JSON.parse(xhr.response));
      }
    });

    /* Setup RequestSuccess strategies */
    UploadXhrStrategy.setRequestSuccessHandler(async function(xhr) {
      var hdr = xhr.getResponseHeader('Authorization');
      if (hdr) await store.dispatch('auth/setAuthHeader', hdr);
    });


    /* Setup RequestComplete strategies */
    UploadXhrStrategy.setRequestCompleteHandler(function(response, loader) {
      //our upload component may pass a null response if there is an 
      //error in a pre-flight check, so any use of response should be 
      //placed in a null check condition
      cfpLoadingBar.complete();
      $timeout.cancel(loader);
    });

    VueResourceStrategy.setRequestCompleteHandler(async function(response, loader) {
      cfpLoadingBar.complete();
      $timeout.cancel(loader);
      
      const isSoftError = response.status > 399
        && response.status < 500
        && response.status !== 401 
        && response.status !== 403;

      if(isSoftError) {
        PrimaryMessageBus.$emit('send-error-message', response.body[0].message);
        return Promise.reject(response);
      } else if (response.status === 401 || response.status === 403) {
        await store.dispatch('auth/logout');
        locationSvc.changeView(appSettings.viewLogin);
        return Promise.reject(response);
      } else if (response.status >= 400) {
        $rootScope.$broadcast('cfpLoadingBar:error', response.body);
      } else {
        var hdr = response.headers.get('Authorization');
        if (hdr) await store.dispatch('auth/setAuthHeader', hdr);
        return response
      }
    });

    // VUE INTERCEPTOR SETUP
    Vue.http.interceptors.push((request, next)  => {
      if (request.url) {
        const loader = VueResourceStrategy.handlePreRequest(request, loader);
        next((response) => {
          return VueResourceStrategy.handleRequestComplete(response, loader);
        });
      }
    });
    
    AxiosStrategy.setRequestSuccessHandler(async function(response) {
      cfpLoadingBar.complete();
      if (response && response.config) $timeout.cancel(response.config.cbLoader);

      var hdr = response && response.headers && response.headers.authorization;
      if (hdr) await store.dispatch('auth/setAuthHeader', hdr);

      return response;
    });

    AxiosStrategy.setRequestErrorHandler(async function(error) {
      cfpLoadingBar.complete();
      if (error && error.config) $timeout.cancel(error.config.cbLoader);

      const response = error.response || {}; // default the object if no response received

      const isSoftError = response.status > 399
        && response.status < 500
        && response.status !== 401
        && response.status !== 403;

      if (isSoftError) {
        PrimaryMessageBus.$emit('send-error-message', response.data[0].message);
        return Promise.reject(response);
      } else if (response.status === 401 || response.status === 403) {
        await store.dispatch('auth/logout');
        locationSvc.changeView(appSettings.viewLogin);
        return Promise.reject(response);
      } else {
        $rootScope.$broadcast('cfpLoadingBar:error', response.data);
        return Promise.reject(response);
      }
    });


    AxiosLocal.interceptors.request.use(function (config) {
      // Do something before request is sent
      return AxiosStrategy.handlePreRequest(config);
    }, function (error) {
      return AxiosStrategy.handleRequestError(error);
    });

    AxiosLocal.interceptors.response.use(function (response) {
      return AxiosStrategy.handleRequestSuccess(response);
    }, function (error) {
      return AxiosStrategy.handleRequestError(error);
    });

    $ngVue = $injector.get('$ngVue');
  }])
  .name;
