import appSettings from "appSettings";

const getDefaultState = () => {
  return {
    featureFlags: null,
    featureTogglingIsEnabled: false,
    redirectUrl: "",
    idPConfigurations: [],
  };
};

// state
const state = getDefaultState();

// getters
export const getters = {
  getIdPConfigurations() {
    return state.idPConfigurations;
  },
  getFeatureFlags() {
    return state.featureFlags;
  },
  hasFeature: (state) => (featureName) => {
    if (state.featureTogglingIsEnabled) {
      return state.featureFlags[featureName] === true;
    } else return true;
  },
  ///TODO: remove once feature toggling is fully implemented
  hasActionCenterRevampedFeature: (state) => () => {
    if (state.featureFlags === null || state.featureFlags === undefined) {
      return false;
    }
    return state.featureFlags["ActionCenterRevampedEnabled"] === true;
  },
  hasConditionalLogicFeature: (state) => () => {
    if (state.featureFlags === null || state.featureFlags === undefined) {
      return false;
    }
    return state.featureFlags["AdminConditionalFormLogicEnabled"] === true;
  },
  hasNewAdminUiMagicLinkFeature: (state) => () => {
    if (state.featureFlags === null || state.featureFlags === undefined) {
      return false;
    }
    return state.featureFlags["NewAdminUiMagicLinkEnabled"] === true;
  },
  hasNewUiImpersonateFeature: (state) => () => {
    if (state.featureFlags === null || state.featureFlags === undefined) {
      return false;
    }
    return state.featureFlags["AdminNewUiImpersonateEnabled"] === true;
  },
  getFeatureTogglingIsEnabled() {
    return state.featureTogglingIsEnabled;
  },
  getAreFeatureFlagsLoaded() {
    //called from login.ctrl. if browser is refreshed, our vuex feature state is wiped
    //so we need to get feature flags directly from appsettings instead of the store
    let featureFlagToggle = false;
    if (appSettings.hasOwnProperty("featureFlagFeatureToggle")) {
      featureFlagToggle = JSON.parse(appSettings.featureFlagFeatureToggle);
    }
    if (featureFlagToggle === false) {
      return true;
    } else if (
      featureFlagToggle === true &&
      (state.featureFlags === null || state.featureFlags === undefined)
    ) {
      return false;
    }
    return true;
  },
  getRedirectUrl() {
    return state.redirectUrl;
  },
  getClickInitiatedSsoUrl() {
    return state.clickInitiatedSsoUrl;
  },
  getUserName: (state) => () => {
    return state.userName;
  },
};

// actions
export const actions = {
  reset({ commit }) {
    commit("resetState");
  },
};

// mutations
export const mutations = {
  setFeatureFlags(state, featureFlags) {
    state.featureFlags = featureFlags;
  },
  setFeatureFlagToggle(state, isEnabled) {
    state.featureTogglingIsEnabled = JSON.parse(isEnabled);
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setRedirect(state, redirectUrl) {
    state.redirectUrl = redirectUrl;
  },
  setClickInitiatedSsoUrl(state, clickInitiatedSsoUrl) {
    state.clickInitiatedSsoUrl = clickInitiatedSsoUrl;
  },
  setIdPConfigurations(state, configs) {
    state.idPConfigurations = configs;
  },
  setUserName(state, name) {
    state.userName = name;
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
