import { i18n } from 'common/lang-config';

export default function ErrorDirective ($location, $compile, appSettings) {
	var linker = function (scope, element, attrs) {
		var emailAddress = i18n.t('common.error.email');
		var email = '<a href="mailto:' + emailAddress + '?subject=Candidate%20code%3a%20' + scope.message.code + '">' + emailAddress + '</a>' ;
		var phone = '1-855-283-5583';
		if ( angular.$$lowercase($location.absUrl()).indexOf("myonboarding") === -1 &&
			angular.$$lowercase($location.absUrl()).indexOf("myoffboarding") === -1 ) {
			email = '<a href="mailto:' + emailAddress + '?subject=Code%3a%20' + scope.message.code + '">' + emailAddress + '</a>' ;
			phone = '952-283-3454';
		}
		scope.contact = "7am-7pm CT Monday-Friday<br />phone:&nbsp;" + phone + "<br />email:&nbsp;" + email;
		var content = angular.element(document.querySelector('#dContent'));
		content.html(scope.contact);
		$compile(content.contents())(scope);
	}
	return {
		restrict: "E",
		replace:true,
		link: linker,
		template: require('common/error.html'),
		scope: {
			message: '=',
			errorClick: '&'
		}
	};
};

ErrorDirective.$inject = ['$location', '$compile', 'appSettings'];